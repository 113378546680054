<template>
  <section class="buying-on-apple">
    <h1>Проблема с покупкой книг на устройствах Apple</h1>
    <ol class="buying-on-apple__list">
      <li class="buying-on-apple__item">
        <h2>Авторизуйтесь на сайте</h2>
        <p>Убедитесь, что и на сайте, и в приложении у вас используется одна и та же учетная запись.</p>
        <p>Если у вас нет учетной записи, зарегистрируйтесь, чтобы не потерять доступ к своим книгам</p>
      </li>
      <li class="buying-on-apple__item">
        <h2>Воспользуйтесь поиском</h2>
        <p>Найдите на сайте книгу, которую вы не смогли купить в приложении</p>
      </li>
      <li class="buying-on-apple__item">
        <h2>Купите книгу</h2>
        <p>Выберите наиболее удобный для вас способ оплаты</p>
      </li>
      <li class="buying-on-apple__item">
        <h2>Читайте или слушайте</h2>
        <p>После покупки на странице книги нажмите на кнопку «Читать в приложении»</p>
      </li>
    </ol>
  </section>
</template>

<script>
export default {
  name: "BuyingOnApple",
};
</script>

<style lang="stylus">
.buying-on-apple {
  display grid
  padding 30px
  padding-top 0
  width 100%

  h1 {
    color var(--dark)
    +below(560px) {
      font-size 1.725em
    }
  }

  &__list {
    display flex
    flex-direction column
    padding 0
    margin 0
    +below(560px) {
      h2 {
        font-size 1.225em
      }
    }
  }
}
</style>
